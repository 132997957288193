import { throwIfResponseError } from "apis/apiUtilities";
import { authGetResource } from "utilities/msalFetches";

class AzureBlobsApi {
  public async getDataUri(uri: string): Promise<string> {
    let response = await authGetResource(uri);

    if (response.status === 403) {
      throw new Error("Requested image authorization has expired. Please try loading the job again.");
    }

    throwIfResponseError(response);

    let data = await response.blob();

    let dataUri = await new Promise<string>(resolve => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.readAsDataURL(data);
    });

    return dataUri;
  }
}

export default new AzureBlobsApi();