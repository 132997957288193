import useSelector from "store/useSelector";
import React from "react";
import "./JobDocs.scoped.scss";
import { filesFilter, isFilePDF, validateAndReadFile } from "utilities/fileUtilities";
import { showErrorToast } from "store/toast/toastActions";
import { useDispatch } from "react-redux";
import removeIcon from "media/icons/dls/delete.svg";
import { deleteJobDoc, saveJobDoc } from "store/execution/executionActions";

import { ExecutionMode } from "store/execution/executionTypes";

const JobDocs: React.FC = () => {
  const {
    job,
    mode,
  } = useSelector(state => state.execution);
  
  const dispatch = useDispatch();
  let pdfFileInputElement: HTMLInputElement | null = null;

  const openPDF = (fileUrl: string) => {
    const mfurl = fileUrl.replace("data:application/pdf;base64,", "");
    var byteCharacters = atob(mfurl);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], {
      type: 'application/pdf;base64'
    });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }

  if (!job) {
    return null;
  }

  const onFileChosen = async (event: React.FormEvent<HTMLInputElement>) => {
    let tgt = event.target as HTMLInputElement;

    if (!tgt || !tgt.files || !tgt.files.length) {
      return;
    }

    try {
      const fileData = await validateAndReadFile(tgt.files[0]);

      //Verify file uploaded is PDF only
      if (!isFilePDF(fileData.filename)) {
        dispatch(showErrorToast("Only PDF file can be uploaded"));
        return;
      }

      //alert(tgt.files[0].size);20971520
      const totalSize = job.jobDocs.reduce((sum, current) => sum + current.fileSize, 0);
      if (totalSize + tgt.files[0].size > 20971520) {
        dispatch(showErrorToast("You cannot add more than 20 mb of Files"));
      } else {
        if (job?.id) {
          dispatch(saveJobDoc({
            jobDoc: {
              fileName: fileData.filename,
              fileSize: tgt.files[0].size,
              fileContent: fileData.dataUri,
            },
            jobId: job.id,
          }));
        }
      }
      tgt.value = "";
    } catch (err: any) {
      dispatch(showErrorToast(err.message || err));
    }
  }

  const showChoosePDFFile = () => {
    pdfFileInputElement?.click();
  }

  const DeleteJobDoc = (filename: string) => {
    dispatch(deleteJobDoc({
      filename: filename,
      jobId: job.id,
    }));
  }

  return (
    <>
      <div className="divItems">
        <span className={mode === ExecutionMode.Offline ? "hideElement" : ""}>
          <input
            type="file"
            accept={filesFilter}
            ref={(input) => { pdfFileInputElement = input }}
            onChange={onFileChosen}
          />
          <button
            className={`primary-button`}
            title="Upload PDF"
            onClick={showChoosePDFFile}

          >
            +Add PDF
          </button>
        </span>
        {job?.jobDocs && job.jobDocs.map((x, i) => (
          <div
            className={`sw-row input`}
            key={i + "rowinput"}>
            <div
              className="sw-info"
              key={i + "filename"}
            >
              <div
                className="title"
                key={i + "filecontent"}
                onClick={() => openPDF(x.fileContent)}
              >
                {x.fileName}
              </div>
            </div>
            <span
              className={`toggle-sw-button input Remove ${mode === ExecutionMode.Offline ? "hideElement" : ""}`}
              title="Remove"
              key={i + "remove"}
              onClick={() => DeleteJobDoc(x.fileName)}
            >
              <img
                className="icon-small"
                src={removeIcon}
                key={i + "removeicon"}
                alt="Remove File"
              />
            </span>
          </div>
        ))}
      </div>
    </>
  );
}
export default JobDocs;