import React, { Suspense } from 'react';
import './index.scss';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { rootReducer } from './store/rootStore'
import { Provider } from 'react-redux'
import createSagaMiddleware from "redux-saga";
import rootSaga from "./store/rootSaga";
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { authRequest, msalPublicClient } from 'msalConfig';
import AppStartup from 'components/startup/AppStartup';
import config from 'config';
import { showInfoToast } from 'store/toast/toastActions';
import { setUpdatedServiceWorker } from 'store/offline/offlineActions';
import './i18n';
import ModalSpinner from 'components/common/ModalSpinner';
import i18n from 'i18n';
import { InteractionType } from '@azure/msal-browser';
import { createRoot } from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';

const t = i18n.getFixedT(null, 'mainMenu');

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: {
        ignoredActions: ['myJobs/setMyJobs', 'execution/loadJobOnly'],
        ignoredPaths: ['execution.jobPaperExecution.timestamp', 'myJobs.jobHeaders', 'execution.job.startTime', 'execution.stepResponses', 'execution.log', 'execution.paperExecutions'],
      },
    }),
    sagaMiddleware,
  ],
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <MsalProvider
      instance={msalPublicClient}
    >
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={authRequest}
          errorComponent={(err) => (<p>Failed to authenticate: {err.error?.errorMessage}</p>)}
          loadingComponent={() => (<p>Logging In...</p>)}
        >
        <Suspense 
          fallback={<ModalSpinner />}
        >
          <AppStartup>
            <App />          
          </AppStartup>
        </Suspense>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  </Provider>
);

export const portalRoot = (document.getElementById("portal") as HTMLElement);

const serviceWorkerSetting = config.serviceWorker.isEnabled;

if (serviceWorkerSetting?.toLowerCase() === "true") {
  serviceWorkerRegistration.register({
    onSuccess: () => store.dispatch(showInfoToast(
      t("The website has been saved for offline use."))),
    onUpdate: (registration) => store.dispatch(setUpdatedServiceWorker(registration)),
  });
} else {
  serviceWorkerRegistration.unregister();
}