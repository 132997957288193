import { all, call, put, takeLatest, select, takeEvery } from 'redux-saga/effects';
import {
  IExecutionState,
  ExecutionMode,
  ILoadExecutionPayload,
  ILogJobActionPayload,
  IExecutionJob,
  IStepIdentifier,
  IExecutionJobSW,
} from './executionTypes';
import {
  setIsUploadingStepResponses,
  setIsJobCompleting,
  setCompleteJobError,
  setUploadResponsesError,
  setJobStatus,
  setIsCompleteJobModalVisible,
  setIsJobCompletedModalVisible,
  setResponsesAreDirty,
  logJobAction,
  loadExecution,
  completeJob,
  cacheCompleteJob,
  loadingFailed,
  beginLoading,
  finishLoading,
  setLog,
  setComments,
  saveShowAllECLs,
  saveExecutionData,
  handleRealtimeStepUpdate,
  beginRealtimeStepLoads,
  finishRealtimeStepLoads,
  handleRealtimeCommentsUpdate,
  finishRealtimeCommentLoads,
  handleRealtimeJobHistoryLoad,
  finishRealtimeJobHistoryLoad,
  setPaperExecutions,
  handleRealtimePaperExecution,
  finishRealtimePaperExecLoad,
  beginRealtimePaperExecLoad,
  saveOnlineSWUserFeedback,
  getOnlineSWUserFeedbacks,
  setOnlineSWUserFeedback,
  saveOfflineSWUserFeedback,
  addOfflineSWUserFeedback,
  addSWUserFeedback,
  launchUserSentimentSurvey,
  resetSWResponses,
  resetJobPaperExecution,
  finishRealtimeJobPaperExecLoad,
  setJobPaperExecutions,
  setShowAllECLs,
  setIsSavingShowAllECLs,
  saveStepsDataAndCompleteJob,
  resetSW,
  clearResetSW,
  updateJobSwidForDuplicate,
  updateStepResponsesJobSWidForDuplicate,
  updatePaperExecsJobSWidForDuplicate,
  updateCommentsJobSWidForDuplicate,
  updateIsDirtyForDuplicateSW,
  updateResetSWJobSWidForDuplicate,
  loadJobOnly,
  loadJobSW,
  addJobDoc,
  removeJobDoc,
  saveJobDoc,
  deleteJobDoc
} from './executionActions';
import { getResponseErrorMessage } from 'utilities/validationErrorHelpers';
import JobsApi from 'apis/jobs/JobsApi';
import { ISWPJobSW, SWPJobStatus, ISWPJobLogItem, IOfflineSWPJobLog, ISWPJob, ISWUserFeedback } from 'interfaces/jobs/JobInterfaces';
import SWApi from 'apis/sw/SWApi';
import { RootState } from 'store/rootStore';
import { IStepResponse, ComponentResponseType, IUserImageData, IGetJobResponsesResult, IResponseImageRef, OfflineStepResponse, IStepComment, IIdbStepComments, IStepCommentResponse, Connectivity, IRealtimeStepResponseInfo, IGetPaperExecutionResponse, IIdbPaperExecution, IPaperExecution, IIdbStepCommentAttachment, StepCommentActions, IJobCompletion, IJobCancellation, IIdbResetSW, IJobPaperExecution, IIdbJobPaperExecution, IDuplicateJobSW, IDuplicateSWRequest, IJobDocData } from 'interfaces/execution/executionInterfaces';
import IdbApi from 'apis/idb/IdbApi';
import ExecutionApi from 'apis/execution/ExecutionApi';
import { IGetSWResponse, ISW, ISWAttachmentRef, IOfflineSW } from 'interfaces/sw/SWInterfaces';
import AzureBlobsApi from 'apis/azureBlobs/AzureBlobsApi';
import { showErrorToast, showSuccessToast } from 'store/toast/toastActions';
import { refreshAwaitingSync } from 'store/offline/offlineActions';
import { Action } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { formatSW } from 'apis/sw/SWFormatters';
import { batchCalls } from "../saga-helpers";
import { IAzureBlobRef } from 'interfaces/azureBlobs/azureBlobsInterfaces';
import { loadResponseImageIfNotInDb, uploadCommentAttachment, uploadUserImage } from 'store/offline/offlineSagas';
import { getSWInstanceTitle } from 'utilities/swUtilities';
import i18n from 'i18n';
import config from 'config';
import { acquireUSSAccessToken } from 'msalConfig';
import { IManageUserUser } from 'interfaces/user/UserInterfaces';

const t = i18n.getFixedT(null, 'executionSaga');

export default function* watchExecutionSagas() {
  yield all([
    watchLoadExecution(),
    watchSyncOrCacheExecutionData(),
    watchCompleteJob(),
    watchCacheCompleteJob(),
    watchHandleRealtimeStepUpdate(),
    watchHandleRealtimeCommentsUpdate(),
    watchHandleRealtimeJobHistoryLoad(),
    watchHandleRealtimePaperExecution(),
    watchSaveOnlineSWUserFeedback(),
    watchGetOnlineSWUserFeedbacks(),
    watchSaveOfflineSWUserFeedback(),
    watchUserSentimentSurvey(),
    watchResetSWResponses(),
    watchSaveShowAllECLs(),
    watchResetJobPaperExecution(),
    watchSaveStepsDataAndCompleteJob(),
    watchSaveJobDoc(),
    watchDeleteJobDoc(),
  ]);
}

function* watchLoadExecution() {
  yield takeLatest(loadExecution, loadExecutionAsync);
}

function* watchSyncOrCacheExecutionData() {
  yield takeLatest(saveExecutionData, saveExecutionDataAsync);
}

function* watchSaveJobDoc() {
  yield takeLatest(saveJobDoc, saveJobDocAsync);
}

function* watchDeleteJobDoc() {
  yield takeLatest(deleteJobDoc, deleteJobDocAsync)
}

function* watchCompleteJob() {
  yield takeLatest(completeJob, completeJobAsync);
}


function* watchSaveStepsDataAndCompleteJob() {
  yield takeLatest(saveStepsDataAndCompleteJob, saveStepsAndCompleteJobAsync);
}

function* watchSaveShowAllECLs() {
  yield takeLatest(saveShowAllECLs, saveShowAllECLsAsync);
}

function* watchCacheCompleteJob() {
  yield takeLatest(cacheCompleteJob, cacheCompleteJobAsync);
}

function* watchUserSentimentSurvey() {
  yield takeLatest(launchUserSentimentSurvey, launchUserSentimentSurveyAsync);
}

function* watchResetSWResponses() {
  yield takeLatest(resetSWResponses, resetSWResponsesAsync);
}

function* watchResetJobPaperExecution() {
  yield takeLatest(resetJobPaperExecution, resetJobPaperExecutionAsync);
}

function* saveJobDocAsync(action: Action) {
  if (!saveJobDoc.match(action)) {
    return;
  }
  try {
    yield put(setIsUploadingStepResponses(true));
    yield call(ExecutionApi.uploadJobDoc, action.payload.jobDoc.fileName, action.payload.jobDoc.fileContent, action.payload.jobId);
    yield put(addJobDoc(action.payload));

    yield put(setIsUploadingStepResponses(false));
    yield put(showSuccessToast("Job Doc added successfully"));
  } catch (err: any) {
    yield put(setIsUploadingStepResponses(false));
    yield put(showErrorToast(("Error in Adding job doc " + getResponseErrorMessage(err))));
  }
}

function* deleteJobDocAsync(action: Action) {
  if (!deleteJobDoc.match(action)) {
    return;
  }
  try {
    yield put(setIsUploadingStepResponses(true));
    yield call(ExecutionApi.deleteJobDoc, action.payload.filename, action.payload.jobId);
    yield put(removeJobDoc(action.payload));
    yield put(setIsUploadingStepResponses(false));
    yield put(showSuccessToast("Job Doc deleted successfully"));
  } catch (err: any) {
    yield put(showErrorToast(("Error in deleting job doc " + getResponseErrorMessage(err))));
  }
}

function* loadExecutionAsync(action: Action) {
  if (!loadExecution.match(action)) {
    return;
  }

  if (action.payload.executionMode === ExecutionMode.Online) {
    yield call(loadOnlineExecutionAsync, action.payload);
  } else if (action.payload.executionMode === ExecutionMode.Offline) {
    yield call(loadOfflineExecutionAsync, action.payload);
  }
}

function* loadOnlineExecutionAsync(action: ILoadExecutionPayload) {
  yield put(beginLoading(action));

  let job: IExecutionJob;
  let log: ISWPJobLogItem[];
  let comments: IStepComment[];
  let stepResponses: IStepResponse[] = [];
  let paperExecutions: IPaperExecution[] = [];
  let jobPaperExecution: IJobPaperExecution = {
    jobId: 0,
    imageFilename: "",
    isDirty: false,
    isOnServer: false,
    timestamp: new Date(),
    userEmail: ""
  };
  let swsToLoad: ISWPJobSW[] = [];
  let foundStartJobSWId: number;

  // First, load the job itself.
  try {
    // Call jobs api to get the job.
    const jobResponse: ISWPJob = yield call(JobsApi.getJob, action.jobId);
    swsToLoad = jobResponse.sws;
    foundStartJobSWId = jobResponse.foundStartJobSWId;

    job = {
      ...jobResponse,
      sws: new Array(swsToLoad.length),
    };
  } catch (err: any) {
    // Error loading job. Quit executing saga.
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load job')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
    return;
  }

  // Call jobs api to get job log.
  try {
    log = yield call(JobsApi.getJobLog, action.jobId);
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load job history log')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
    return;
  }

  // Call execution api to get step comments.
  try {
    const stepResponse: IStepCommentResponse =
      yield call(ExecutionApi.getStepComments,
        action.jobId);

    if (stepResponse.azureAttachments.length) {
      // Load and cache all the step comment atts.
      yield call(batchCalls,
        5,
        stepResponse.azureAttachments,
        cacheStepCommentAtt);
    }

    comments = stepResponse.comments;
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load job step comments')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
    return;
  }


  // Load any previous users' responses.
  let getJobResponsesResult: IGetJobResponsesResult;
  try {
    getJobResponsesResult = yield call(ExecutionApi.getResponses, action.jobId);
    stepResponses = getJobResponsesResult.stepResponses;

    // Load user response images simultaneously.
    if (getJobResponsesResult.images.length) {
      try {
        yield batchCalls(3, getJobResponsesResult
          .images
          .map(i => ({
            imageRef: i,
            jobId: action.jobId,
          })),
          downloadAndCacheUserImage);
      } catch (err: any) {
        yield put(loadingFailed({
          loadErrors: [
            `${t('Failed to load response images')}: ${getResponseErrorMessage(err)}`,
          ],
        }));
        return;
      }
    }
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load responses')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
  }
  // Load Job Docs from the server.
  try {
    if (job.jobDocs) {
      for (let index = 0; index < job.jobDocs.length; index++) {
        const element = job.jobDocs[index];
        const isInCache: boolean = yield call([IdbApi, IdbApi.isJobDocInCache], element.fileName);

        if (!isInCache) {
          // If the image isn't already in the cache, retrieve it and put it in the cache.
          let dataUri: string = yield call(AzureBlobsApi.getDataUri, element.fileContent);

          yield call([IdbApi, IdbApi.cacheJobDoc],
            element.fileName,
            job.id,
            dataUri);

          element.fileContent = dataUri;
        } else {
          const jobDoc: IJobDocData = yield call([IdbApi, IdbApi.getJobDoc], element.fileName);
          if (jobDoc) {
            element.fileContent = jobDoc.data;
          }
        }
      }
    }
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load responses')}: ${getResponseErrorMessage(err)}`,
      ]
    }))
  }

  // Load paper executions from the server.
  try {
    const paperExecs: IGetPaperExecutionResponse[] = yield call(
      ExecutionApi.getPaperExecutions,
      action.jobId);

    if (paperExecs.length) {
      // Load all their images.
      const loadArgs = paperExecs.map((x): {
        imageRef: IResponseImageRef,
        jobId: number,
      } => ({
        imageRef: {
          filename: x.proofFilename,
          absoluteUri: x.absoluteUri,
        },
        jobId: action.jobId,
      }));

      yield batchCalls(3, loadArgs, downloadAndCacheUserImage);

      paperExecutions = paperExecs
        .filter(a => a.isJobPaperExecution === false)
        .map((x): IPaperExecution => ({
          userEmail: x.userEmail,
          timestamp: x.timestamp,
          imageFilename: x.proofFilename,
          jobSWId: x.jobIdOrJobSWId,
          isOnServer: true,
          isDirty: false,
        }));

      const jobPaperExec = paperExecs.filter(a => a.isJobPaperExecution === true);
      if (jobPaperExec.length > 0) {
        jobPaperExecution = {
          jobId: jobPaperExec[0].jobIdOrJobSWId,
          imageFilename: jobPaperExec[0].proofFilename,
          userEmail: jobPaperExec[0].userEmail,
          isDirty: false,
          isOnServer: true,
          timestamp: jobPaperExec[0].timestamp,
        }
      }
    }
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load responses')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
  }

  // Load any previous users' responses.

  try {
    const getJobResponsesResult: IGetJobResponsesResult = yield call(ExecutionApi.getResponses, action.jobId);
    stepResponses = getJobResponsesResult.stepResponses;

    // Load user response images simultaneously.
    if (getJobResponsesResult.images.length) {
      try {
        yield batchCalls(3, getJobResponsesResult
          .images
          .map(i => ({
            imageRef: i,
            jobId: action.jobId,
          })),
          downloadAndCacheUserImage);
      } catch (err: any) {
        yield put(loadingFailed({
          loadErrors: [
            `${t('Failed to load response images')}: ${getResponseErrorMessage(err)}`,
          ],
        }));
        return;
      }
    }
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load responses')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
  }

  // load the one standardwork to show with job.
  let firstItemLoadedSortOrder = swsToLoad[0].sortOrder;
  let sws: ISW[] = [];
  try {
    for (let i = 0; i < swsToLoad.length; i++) {
      const jobSW = swsToLoad[i];

      if (jobSW.jobSWId === foundStartJobSWId) { // check sw from job response.
        let sw: ISW = yield call(loadSW, jobSW);
        sws.push(sw);
        firstItemLoadedSortOrder = jobSW.sortOrder;
        job.sws[i] = {
          ...sw,
          jobSWId: jobSW.jobSWId,
          sortOrder: swsToLoad[i].sortOrder,
          showCriticalSteps: false,
        };

      } else { // inject all the rest just as headers
        job.sws[i] = {
          docType: '',
          docVersion: '',
          id: jobSW.swId,
          version: jobSW.version,
          title: jobSW.title ? jobSW.title : '',
          description: jobSW.description,
          ppe: [],
          notices: [],
          refDocs: [],
          steps: [],
          hasCriticalSteps: false,
          jobSWId: jobSW.jobSWId,
          sortOrder: jobSW.sortOrder,
          showCriticalSteps: false,
        };
      }
    }

    job.sws.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load standard work for job')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
    return;
  }

  yield put(loadJobOnly({
    job,
    mode: action.executionMode,
    stepResponses,
    log,
    comments,
    paperExecutions,
    jobPaperExecution,
  }));

  // load the rest in background... but only those after first one
  for (let i = 0; i < swsToLoad.length; i++) {
    const jobSW = swsToLoad[i];

    if (jobSW.sortOrder <= firstItemLoadedSortOrder) {
      continue; // dont load the one we already loaded or any before it
    }

    let alreadyDownloadedSW = sws.find(sw => sw.version === jobSW.version
      && sw.id === jobSW.swId);

    if (!alreadyDownloadedSW) {
      //we need to download this sw
      alreadyDownloadedSW = yield call(loadSW, jobSW);
    }

    if (!alreadyDownloadedSW) {
      throw new Error(t('The job requires SW {title}/{version} but it was not loaded!', { title: jobSW.title, version: jobSW.version }));
    }

    yield put(loadJobSW({ jobId: job.id, sw: alreadyDownloadedSW, jobSWId: jobSW.jobSWId }));
  }

  // load the rest in background... but only those before and leading up to first one
  for (let i = 0; i < swsToLoad.length; i++) {
    const jobSW = swsToLoad[i];

    if (jobSW.sortOrder >= firstItemLoadedSortOrder) {
      continue; // dont load the one we already loaded or any after it
    }

    let alreadyDownloadedSW = sws.find(sw => sw.version === jobSW.version
      && sw.id === jobSW.swId);

    if (!alreadyDownloadedSW) {
      //we need to download this sw
      alreadyDownloadedSW = yield call(loadSW, jobSW);
    }

    if (!alreadyDownloadedSW) {
      throw new Error(t('The job requires SW {title}/{version} but it was not loaded!', { title: jobSW.title, version: jobSW.version }));
    }

    yield put(loadJobSW({ jobId: job.id, sw: alreadyDownloadedSW, jobSWId: jobSW.jobSWId }));
  }
}

function* loadOfflineExecutionAsync(action: ILoadExecutionPayload) {
  yield put(beginLoading(action))

  let job: IExecutionJob;
  let log: ISWPJobLogItem[];
  let stepResponses: IStepResponse[] = [];
  let stepComments: IStepComment[] = [];
  let paperExecutions: IPaperExecution[] = [];
  let jobPaperExecution: IJobPaperExecution = {
    jobId: 0,
    imageFilename: "",
    isDirty: false,
    isOnServer: false,
    timestamp: new Date(),
    userEmail: ""
  }
  let swsToLoad: ISWPJobSW[] = [];

  // First, load the job itself.
  try {
    const jobResponse: (ISWPJob | undefined) = yield call([IdbApi, IdbApi.getCachedJob], action.jobId);

    if (!jobResponse) {
      throw new Error(t("Job not found."));
    }

    swsToLoad = jobResponse.sws;

    job = {
      ...jobResponse,
      sws: new Array(swsToLoad.length),
    };
  } catch (err: any) {
    // Error loading job. Quit executing saga.
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load job from cache')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
    return;
  }

  // Load cached job log.
  try {
    const loadedLog: IOfflineSWPJobLog = yield call([IdbApi, IdbApi.getCachedJobLog], action.jobId);
    log = loadedLog.log;
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load job history log from cache')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
    return;
  }

  // Load cached responses.
  try {
    stepResponses = yield call([IdbApi, IdbApi.getCachedJobResponses], action.jobId);
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load responses from cache')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
    return;
  }

  // Load cached comments.
  try {
    const cachedStepComments: IIdbStepComments | undefined =
      yield call([IdbApi, IdbApi.getCachedStepComments], action.jobId);

    if (cachedStepComments) {
      stepComments = cachedStepComments.comments;
    }
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load responses from cache')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
    return;
  }

  // Load UserSWFeedback
  try {
    const cachedUserSWFeedback: ISWUserFeedback[] =
      yield call([IdbApi, IdbApi.getCachedUserSWFeedback], action.jobId);
    if (cachedUserSWFeedback) {
      job.swUserFeedback = job.swUserFeedback.concat(cachedUserSWFeedback);
    }
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load User SW Feedback from cache')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
    return;
  }

  // Load Cached Job Docs.
  try {
    if (job.jobDocs) {
      for (let index = 0; index < job.jobDocs.length; index++) {
        const element = job.jobDocs[index];
        const isInCache: boolean = yield call([IdbApi, IdbApi.isJobDocInCache], element.fileName);

        if (!isInCache) {
          // If the image isn't already in the cache, retrieve it and put it in the cache.
          let dataUri: string = yield call(AzureBlobsApi.getDataUri, element.fileContent);

          yield call([IdbApi, IdbApi.cacheJobDoc],
            element.fileName,
            job.id,
            dataUri);

          element.fileContent = dataUri;
        } else {
          const jobDoc: IJobDocData = yield call([IdbApi, IdbApi.getJobDoc], element.fileName);
          if (jobDoc) {
            element.fileContent = jobDoc.data;
          }
        }
      }
    }
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load responses')}: ${getResponseErrorMessage(err)}`,
      ]
    }))
  }

  // Load cached Job Paper Execution.
  try {
    const cachedJobPaperExecution: IIdbJobPaperExecution =
      yield call([IdbApi, IdbApi.getCachedJobPaperExecution], action.jobId);
    if (cachedJobPaperExecution) {
      jobPaperExecution.jobId = cachedJobPaperExecution.jobId;
      jobPaperExecution.imageFilename = cachedJobPaperExecution.imageFilename;
      jobPaperExecution.userEmail = cachedJobPaperExecution.userEmail;
      jobPaperExecution.timestamp = cachedJobPaperExecution.timestamp;
      jobPaperExecution.isDirty = false;
      jobPaperExecution.isOnServer = cachedJobPaperExecution.isOnServer;
    }
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load Job Paper execution from from cache')}`,
      ],
    }));
  }

  // Load cached paper executions
  try {
    const cachedPaperExecutions: IIdbPaperExecution[] =
      yield call([IdbApi, IdbApi.getCachedPaperExecutions], action.jobId);

    if (cachedPaperExecutions) {
      paperExecutions = cachedPaperExecutions.map(x => ({
        userEmail: x.userEmail,
        timestamp: x.timestamp,
        imageFilename: x.imageFilename,
        jobSWId: x.jobSWId,
        isOnServer: x.isOnServer,
        isDirty: false,
      }));
    }
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load responses from cache')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
    return;
  }

  // Load cached SWs.
  try {
    const sws: IOfflineSW[] = yield call([IdbApi, IdbApi.getCachedSWs], action.jobId);

    for (let i = 0; i < swsToLoad.length; i++) {
      const jobSW = swsToLoad[i];

      let sw = sws.find(sw => sw.swVersion === jobSW.version
        && sw.swId === jobSW.swId);

      if (!sw) {
        throw new Error(t('The job requires SW {title}/{version} but it was not found in device cache!', { title: jobSW.title, version: jobSW.version }));
      }

      const jobSWId = swsToLoad[i].jobSWId;

      if (!jobSWId) {
        throw new Error(t('The job SW {title}/{version} is missing JobSWId!', { title: jobSW.title, version: jobSW.version }));
      }

      let formattedSW = formatSW(sw.swJson);

      job.sws[i] = {
        ...formattedSW,
        jobSWId,
        sortOrder: jobSW.sortOrder,
        showCriticalSteps: false,
      };
    }

    // Sort by sort order.
    job.sws.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);
  } catch (err: any) {
    yield put(loadingFailed({
      loadErrors: [
        `${t('Failed to load standard work from cache for job')}: ${getResponseErrorMessage(err)}`,
      ],
    }));
    return;
  }

  // If the job is not complete, load job completions from the idb
  // and, if any, set job status to completed.
  if (job.status !== SWPJobStatus.Completed) {
    try {
      const completion: IJobCompletion | undefined = yield call([IdbApi, IdbApi.getCachedJobCompletion], action.jobId);
      if (completion) {
        job.status = SWPJobStatus.Completed;
      }
    } catch (err: any) {
      yield put(loadingFailed({
        loadErrors: [
          `${t('Failed to load cached job completion')}: ${getResponseErrorMessage(err)}`,
        ],
      }));
      return;
    }
  }

  // If the job is not cancelled, load job cancellations from the idb
  // and, if any, set job status to cancelled.
  if (job.status !== SWPJobStatus.Cancelled) {
    try {
      const cancellation: IJobCancellation | undefined = yield call([IdbApi, IdbApi.getCachedJobCancellation], action.jobId);
      if (cancellation) {
        job.status = SWPJobStatus.Cancelled;
      }
    } catch (err: any) {
      yield put(loadingFailed({
        loadErrors: [
          `${t('Failed to load cached job cancellations')}: ${getResponseErrorMessage(err)}`,
        ],
      }));
      return;
    }
  }

  yield put(finishLoading({
    job,
    mode: action.executionMode,
    stepResponses,
    log,
    comments: stepComments,
    paperExecutions,
    jobPaperExecution,
  }));
}

function* loadSW(swToLoad: ISWPJobSW) {
  // Get SW and attachment urls from server.
  const getSWResponse: IGetSWResponse = yield call(SWApi.getSW, swToLoad.swId, swToLoad.version);

  // Retrieve SW document from Azure cloud.
  const sw: ISW = yield call(SWApi.getSWDocument, getSWResponse.documentAbsoluteUri);

  // Load all images in batches.
  if (getSWResponse.images.length) {
    yield batchCalls(5,
      getSWResponse.images.map(i => ({
        imageRef: i,
        swId: swToLoad.swId,
        swVersion: swToLoad.version,
      })),
      loadSWImage);
  }

  // Load all ref docs in batches.
  if (getSWResponse.refDocs.length) {
    yield batchCalls(5,
      getSWResponse.refDocs.map(i => ({
        refDocRef: i,
        swId: swToLoad.swId,
        swVersion: swToLoad.version,
      })),
      loadSWRefDoc);
  }

  return sw;
}

function* loadSWImage(img: {
  imageRef: ISWAttachmentRef,
  swId: string,
  swVersion: string
}) {
  const isInCache: boolean = yield call([IdbApi, IdbApi.isSWImageInCache],
    img.swId,
    img.swVersion,
    img.imageRef.filename);

  if (!isInCache) {
    // If the image isn't already in the cache, retrieve it and put it in the cache.
    let dataUri: string = yield call(AzureBlobsApi.getDataUri, img.imageRef.absoluteUri);

    yield call([IdbApi, IdbApi.cacheSWImageData],
      img.swId,
      img.swVersion,
      img.imageRef.filename,
      dataUri);
  }
}

function* loadSWRefDoc(refDoc: {
  refDocRef: ISWAttachmentRef;
  swId: string;
  swVersion: string;
}) {
  const isInCache: boolean = yield call([IdbApi, IdbApi.isSWRefDocInCache],
    refDoc.swId,
    refDoc.swVersion,
    refDoc.refDocRef.filename);

  if (!isInCache) {
    // If the refDoc isn't already in the cache, retrieve it and put it in the cache.
    let dataUri: string = yield call(AzureBlobsApi.getDataUri,
      refDoc.refDocRef.absoluteUri);

    yield call([IdbApi, IdbApi.cacheRefDocData],
      refDoc.swId,
      refDoc.swVersion,
      refDoc.refDocRef.filename,
      dataUri);
  }
}

export function* downloadAndCacheUserImage(args: { imageRef: IResponseImageRef, jobId: number }) {
  const isInCache: boolean = yield call([IdbApi, IdbApi.isUserImageInCache], args.imageRef.absoluteUri);

  if (!isInCache) {
    // If the image isn't already in the cache, retrieve it and put it in the cache.
    let dataUri: string = yield call(AzureBlobsApi.getDataUri, args.imageRef.absoluteUri);

    yield call([IdbApi, IdbApi.cacheUserImageData],
      args.imageRef.filename,
      args.jobId,
      true,
      dataUri);
  }
}

function* saveExecutionDataAsync() {
  const executionMode: ExecutionMode = yield select((store: RootState) => store.execution.mode);
  const connectivityStatus: Connectivity = yield select((store: RootState) => store.offline.isOnline);

  if (executionMode === ExecutionMode.Offline) {
    yield call(cacheExecutionDataAsync);
  } else if (connectivityStatus === Connectivity.Online) {
    yield call(syncExecutionDataAsync);
  } else {
    yield call(handleUploadResponseError, t("No internet connection. Please save data manually using the SAVE DATA button."));
  }
}

function* handleUploadResponseError(errorMsg: string) {
  yield put(setUploadResponsesError(errorMsg));
  yield put(showErrorToast(errorMsg));
}

function* syncExecutionDataAsync() {
  let executionState: IExecutionState = yield select((store: RootState) => store.execution);
  const jobId = executionState.job?.id;

  if (!jobId) {
    yield put(showErrorToast(t("Failed to upload responses No job is currently loaded.")));
    return;
  }

  let resetJobSWs: number[] = executionState
    .resetJobSWIds;
  const duplicateJobSWs: IExecutionJobSW[] | undefined = executionState
    .job?.sws.filter(x => x.jobSWId < 0);

  let stepResponses: IStepResponse[] = executionState
    .stepResponses
    .filter(r => r.isDirty);

  const logItems: ISWPJobLogItem[] = cloneDeep(executionState.log);
  let comments: IStepComment[] = cloneDeep(executionState.stepComments);
  let paperExecs: IPaperExecution[] = cloneDeep(executionState.paperExecutions);
  const jobPaperExec: IJobPaperExecution = cloneDeep(executionState.jobPaperExecution);

  const dirtyLogItems: ISWPJobLogItem[] = logItems
    .filter(l => !l.isOnServer);

  let dirtyComments = comments
    .filter(x => !x.isOnServer);

  let dirtyPaperExecs = paperExecs
    .filter(x => !x.isOnServer);

  if (!resetJobSWs.length
    && !stepResponses.length
    && !logItems.length
    && !dirtyComments.length
    && !dirtyPaperExecs.length
    && !duplicateJobSWs?.length) {
    return;
  }

  yield put(setIsUploadingStepResponses(true));

  let hadAnyFailures = false;

  // Duplicate SW in job
  if (duplicateJobSWs?.length) {
    let swResponses: IDuplicateSWRequest[] = [];
    duplicateJobSWs.forEach(s => {
      swResponses.push({
        JobSWId: s.jobSWId,
        JobId: jobId,
        SWId: s.id,
        Version: s.version,
        SortOrder: s.sortOrder,
      });
    })
    try {
      let newSWs: IDuplicateJobSW[] = yield call(duplicateOnlinejobSWsAsync, swResponses, jobId);
      yield put(updateJobSwidForDuplicate({
        newSWs: newSWs
      }));
      yield put(updateStepResponsesJobSWidForDuplicate({
        newSWs: newSWs
      }));
      yield put(updatePaperExecsJobSWidForDuplicate({
        newSWs: newSWs
      }));
      yield put(updateCommentsJobSWidForDuplicate({
        newSWs: newSWs
      }));
      yield put(updateResetSWJobSWidForDuplicate({
        newSWs: newSWs
      }));
    }
    catch (err: any) {
      yield put(showErrorToast(`${t('Failed to upload duplicate SW')}: ${getResponseErrorMessage(err)}`));
      yield call(handleUploadResponseError, getResponseErrorMessage(err));
      hadAnyFailures = true;
    }
  }

  //updated executionState and other data with new jobswids
  executionState = yield select((store: RootState) => store.execution);
  stepResponses = executionState
    .stepResponses
    .filter(r => r.isDirty);
  paperExecs = cloneDeep(executionState.paperExecutions);
  comments = cloneDeep(executionState.stepComments);
  dirtyComments = comments
    .filter(x => !x.isOnServer);
  dirtyPaperExecs = paperExecs
    .filter(x => !x.isOnServer);
  resetJobSWs = executionState
    .resetJobSWIds;

  if (resetJobSWs.length) {
    try {
      yield call(resetOnlineSWresponsesAsync, resetJobSWs);
      yield put(clearResetSW());
    }
    catch (err: any) {
      yield put(showErrorToast(`${t('Failed to upload reset responses')}: ${getResponseErrorMessage(err)}`));
      yield call(handleUploadResponseError, getResponseErrorMessage(err));
      hadAnyFailures = true;
    }
  }

  // Upload steps and their images (if any).
  if (stepResponses.length) {
    try {
      // Gather any image data needing upload.
      let imagesToUpload: IUserImageData[] = [];

      for (let i = 0; i < stepResponses.length; i++) {
        const response = stepResponses[i];

        if (!response.componentResponses
          || !response.componentResponses.length) {
          continue;
        }

        let imageFilenames: string[] = [];

        // Get the image responses that have image filenames in them.
        response
          .componentResponses
          .forEach(sa => {
            if ((sa.type === ComponentResponseType.Image
              || sa.type === ComponentResponseType.Signature)
              && sa.values.length) {
              sa.values.forEach(fn => imageFilenames.push(fn));
            }
          });

        if (imageFilenames.length) {
          // Filter the images to upload to only unique values.
          // This should not be required but is done as a safety
          // precaution.
          imageFilenames = [...Array.from(new Set(imageFilenames))];

          // Gather the image data
          for (let i = 0; i < imageFilenames.length; i++) {
            const imgData: IUserImageData | undefined = yield call([IdbApi, IdbApi.getUserImageData], imageFilenames[i]);
            if (!imgData) {
              throw new Error(t('Unable to find image {imageFilename} from step {stepId}! Cannot upload responses.', { imageFilename: imageFilenames[i], stepId: response.stepId }));
            }
            if (!imgData.isOnServer) {
              imagesToUpload.push(imgData);
            }
          }
        }
      }

      // Upload all of the images one at a time.
      if (imagesToUpload.length) {
        yield batchCalls(1,
          imagesToUpload.map(x => ({
            image: x,
            isJobInCache: false,
            mode: ExecutionMode.Online,
          })),
          uploadUserImage);
      }

      // Send responses to the server (without attachments).
      yield call(ExecutionApi.uploadResponses,
        jobId,
        stepResponses,
        []);

      // Update all the steps to show they are no longer dirty.
      yield put(setResponsesAreDirty({
        steps: stepResponses.map(s => ({
          stepId: s.stepId,
          jobSWId: s.jobSWId,
        })),
        isDirty: false,
      }));
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to upload step responses')}: ${getResponseErrorMessage(err)}`));
      yield call(handleUploadResponseError, getResponseErrorMessage(err));
      hadAnyFailures = true;
    }
  }

  // Upload comments and their attachments (if any).
  if (dirtyComments.length) {
    try {
      // Handle the attachments one at a time first.
      let commentAttachments: IIdbStepCommentAttachment[] = [];

      for (let i = 0; i < comments.length; i++) {
        const changedAtts = comments[i]
          .attachments
          .filter(x => x.pendingAction === StepCommentActions.Add);

        for (let j = 0; j < changedAtts.length; j++) {
          const att = changedAtts[j];

          let attachment: IIdbStepCommentAttachment | undefined = yield call([IdbApi, IdbApi.getStepCommentAttData], att.filename);

          if (!attachment
            || !attachment.data) {
            throw new Error(t('Failed to load step comment attachment {filename} from cache.', { filename: att.filename }));
          }

          commentAttachments.push(attachment);
        }

        if (commentAttachments.length) {
          if (commentAttachments.length) {
            yield batchCalls(1,
              commentAttachments.map(x => ({
                attachment: x,
                jobId,
              })),
              uploadCommentAttachment);
          }
        }
      }
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to upload step comment attachments')}: ${getResponseErrorMessage(err)}`));
      yield call(handleUploadResponseError, getResponseErrorMessage(err));
      hadAnyFailures = true;
    }

    try {
      // Upload all dirty comments.
      yield call(ExecutionApi.uploadComments, jobId, dirtyComments);

      // Update all the comments' dirty/isOnServer bits.
      comments.forEach(x => {
        x.isDirty = false;
        x.isOnServer = true;
      });

      yield put(setComments(comments));
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to upload step comments')}: ${getResponseErrorMessage(err)}`));
      yield call(handleUploadResponseError, getResponseErrorMessage(err));
      hadAnyFailures = true;
    }
  }

  // Upload dirty paper executions.
  if (jobPaperExec
    && jobPaperExec.jobId !== 0) {

    const dataUri: IUserImageData | undefined = yield call([IdbApi, IdbApi.getUserImageData], jobPaperExec.imageFilename);

    if (!dataUri) {
      throw new Error(t(`Paper Execution Image {imageFilename} not found in cache!`, { imageFilename: jobPaperExec.imageFilename }));
    }

    // Upload the paper execution and its image.
    yield call(ExecutionApi.uploadJobPaperExecution, jobPaperExec, dataUri.data, jobPaperExec.jobId);

  }

  if (dirtyPaperExecs.length) {
    try {
      yield call(batchCalls,
        2,
        dirtyPaperExecs.map(x => ({
          paperExec: x,
          jobId,
        })),
        uploadPaperExecution);

      dirtyPaperExecs.forEach(x => {
        x.isDirty = false;
        x.isOnServer = true;

        // Each of the paper executions successfully uploaded
        // need a new log item.
        const newLogItem: ISWPJobLogItem = {
          jobId,
          action: `${t('Paper executed')} ${getSWInstanceTitle(x.jobSWId, executionState.job?.sws || [])}`,
          userEmail: x.userEmail,
          timestamp: x.timestamp,
          isOnServer: false,
          isDirty: true,
        };

        dirtyLogItems.push(newLogItem);
        logItems.push(newLogItem);
      });

      yield put(setPaperExecutions(paperExecs));
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to upload paper executions')}: ${getResponseErrorMessage(err)}`));
      yield call(handleUploadResponseError, getResponseErrorMessage(err));
      hadAnyFailures = true;
    }
  }

  // Sync any dirty log items.
  if (dirtyLogItems) {
    try {
      // Upload job log to server.
      yield call(ExecutionApi.uploadJobHistoryLog, jobId, dirtyLogItems);
      // If successful, update all the job logs in the store to be isOnServer=true.
      logItems.forEach(x => {
        x.isDirty = false;
        x.isOnServer = true;
      });

      yield put(setLog(logItems));
    } catch (err: any) {
      yield put(showErrorToast(`${t('Failed to upload job history log')}: ${getResponseErrorMessage(err)}`));
      yield call(handleUploadResponseError, getResponseErrorMessage(err));
      hadAnyFailures = true;
    }
  }

  if (!hadAnyFailures) {
    yield put(showSuccessToast(t("Your data was successfully uploaded to the server.")));
  }

  yield put(setIsUploadingStepResponses(false));
}

function* uploadPaperExecution(args: {
  paperExec: IPaperExecution,
  isJobPaperExecution: boolean,
  jobId: number,
}) {
  // Load the image from the Idb.
  const dataUri: IUserImageData | undefined = yield call([IdbApi, IdbApi.getUserImageData], args.paperExec.imageFilename);

  if (!dataUri) {
    throw new Error(t(`Paper Execution Image {imageFilename} not found in cache!`, { imageFilename: args.paperExec.imageFilename }));
  }

  // Upload the paper execution and its image.
  yield call(ExecutionApi.uploadPaperExecution, args.paperExec, dataUri.data, args.jobId);
}

function* cacheExecutionDataAsync() {
  let executionState: IExecutionState = yield select((store: RootState) => store.execution);
  const jobId = executionState.job?.id;

  if (!jobId) {
    yield put(showErrorToast(t("Failed to upload responses No job is currently loaded.")));
    return;
  }

  const duplicateJobSWs: IExecutionJobSW[] | undefined = executionState
    .job?.sws.filter(x => x.jobSWId < 0 && x.isDirty);

  let stepResponses: IStepResponse[] = executionState
    .stepResponses
    .filter(r => r.isDirty);

  const historyLogItems = executionState.log;

  let dirtyComments = executionState.stepComments
    .filter(x => x.isDirty);

  let dirtyPaperExecs = executionState.paperExecutions
    .filter(x => x.isDirty);

  let dirtyJobPaperExec = executionState.jobPaperExecution.isDirty ? executionState.jobPaperExecution : null;

  if (!stepResponses.length
    && !historyLogItems.length
    && !dirtyComments.length
    && !dirtyPaperExecs.length
    && !dirtyJobPaperExec
    && !duplicateJobSWs?.length) {
    return;
  }

  let hadAnyFailures = false;

  if (duplicateJobSWs) {
    let offlineDuplicateSWs: ISWPJobSW[] | undefined =
      duplicateJobSWs
        .map(r => ({
          jobSWId: r.jobSWId,
          swId: r.id,
          title: r.title,
          description: r.description,
          version: r.version,
          sortOrder: r.sortOrder,
        }));

    try {
      yield call([IdbApi, IdbApi.cacheDuplicateSWs], offlineDuplicateSWs, jobId);
      yield put(updateIsDirtyForDuplicateSW({
        jobSWIds: offlineDuplicateSWs?.map(x => x.jobSWId),
        isDirty: false,
      }));
    }
    catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
      hadAnyFailures = true;
    }
  }

  // Cache responses into IDB.
  let offlineResponses: OfflineStepResponse[] = stepResponses
    .map(r => ({
      ...r,
      isOnServer: false,
      isDirty: false,
      jobId,
    }));

  try {
    yield call([IdbApi, IdbApi.cacheJobResponses], offlineResponses);

    yield put(setResponsesAreDirty({
      steps: offlineResponses.map(s => ({
        stepId: s.stepId,
        jobSWId: s.jobSWId,
      })),
      isDirty: false,
    }));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    hadAnyFailures = true;
  }

  // Cache Step Comments to IDB.
  try {
    const idbComments = cloneDeep(executionState.stepComments);
    idbComments.forEach(x => x.isDirty = false);

    yield call([IdbApi, IdbApi.cacheStepComments], {
      jobId: jobId,
      comments: idbComments,
    });

    yield put(setComments(idbComments));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    hadAnyFailures = true;
  }

  // Build list of history log items (so they can be added to).
  let offlineJobHistory: ISWPJobLogItem[] = cloneDeep(historyLogItems)
    .map(r => ({
      ...r,
      isDirty: false,
    }));

  // Cache tje dirty Job Paper Execution to idb.
  try {

    if (dirtyJobPaperExec != null) {
      const newjobPaperExec = cloneDeep(executionState.jobPaperExecution);
      const idbjobpaperExec: IIdbJobPaperExecution = {
        jobId: newjobPaperExec.jobId,
        imageFilename: newjobPaperExec.imageFilename,
        isOnServer: newjobPaperExec.isOnServer,
        userEmail: newjobPaperExec.userEmail,
        timestamp: newjobPaperExec.timestamp,
      }
      yield call([IdbApi, IdbApi.cacheJobPaperExecution], idbjobpaperExec);
      newjobPaperExec.isDirty = false;

      offlineJobHistory.push({
        jobId,
        action: `${t('Job Paper executed')}`,
        userEmail: dirtyJobPaperExec.userEmail,
        timestamp: dirtyJobPaperExec.timestamp,
        isOnServer: false,
        isDirty: false,
      });

      yield put(setJobPaperExecutions(newjobPaperExec));
    }
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    hadAnyFailures = true;
  }
  // Cache the dirty Paper Executions to the idb.
  try {
    const newPaperExecs = cloneDeep(executionState.paperExecutions);
    const dirtyPaperExecs = newPaperExecs.filter(x => x.isDirty);

    if (dirtyPaperExecs.length) {
      yield call([IdbApi, IdbApi.cachePaperExecutions],
        dirtyPaperExecs.map((x): IIdbPaperExecution => ({
          imageFilename: x.imageFilename,
          isOnServer: x.isOnServer,
          jobSWId: x.jobSWId,
          timestamp: x.timestamp,
          userEmail: x.userEmail,
          jobId,
        })));

      dirtyPaperExecs.forEach(x => {
        x.isDirty = false;

        offlineJobHistory.push({
          jobId,
          action: `${t('Paper executed')} ${getSWInstanceTitle(x.jobSWId, executionState.job?.sws || [])}`,
          userEmail: x.userEmail,
          timestamp: x.timestamp,
          isOnServer: false,
          isDirty: false,
        });
      });

      yield put(setPaperExecutions(newPaperExecs));
    }
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    hadAnyFailures = true;
  }

  // Cache log into IDB.
  // setting isDirty of history log to false.
  try {
    yield call([IdbApi, IdbApi.cacheJobLog], {
      jobId: jobId,
      log: offlineJobHistory,
    });

    yield put(setLog(offlineJobHistory));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    hadAnyFailures = true;
  }

  // if (!hadAnyFailures) {
  //   yield put(showSuccessToast(t("Your data has been saved to your device. Please sync data at the earliest opportunity.")));
  // }

  // Refresh the display that indicates how many objects are awaiting upload to the server.
  yield put(refreshAwaitingSync());
}

function* saveStepsAndCompleteJobAsync(action: Action) {
  if (!saveStepsDataAndCompleteJob.match(action)) {
    return;
  }

  yield call(saveExecutionDataAsync);

  const executionMode: ExecutionMode = yield select((store: RootState) => store.execution.mode);
  if (executionMode === ExecutionMode.Offline) {
    yield call(cacheCompleteJobAsync, action);
  } else {
    yield call(completeJobAsync, action);
  }
}

function* completeJobAsync(action: Action) {
  if (!(completeJob.match(action) || saveStepsDataAndCompleteJob.match(action))) {
    return;
  }

  yield put(setIsJobCompleting(true));

  let wasJobCompleted = false;

  try {
    wasJobCompleted = yield call(ExecutionApi.uploadCompletion,
      action.payload.jobId,
      action.payload.deviationMessage,
      action.payload.timestamp,
      action.payload.userEmail);
  } catch (err: any) {
    yield put(setCompleteJobError(getResponseErrorMessage(err)));
  }

  if (wasJobCompleted) {
    // Job is now completed.
    const currentUser: IManageUserUser = yield select((store: RootState) => store.auth.currentUser);
    yield put(setJobStatus(SWPJobStatus.Completed));
    yield put(setIsCompleteJobModalVisible(false));
    yield put(setIsJobCompletedModalVisible(true));
    yield put(launchUserSentimentSurvey({ currentUser: currentUser.email }));
  }

  yield put(setIsJobCompleting(false));
}

function* saveShowAllECLsAsync(action: Action) {
  yield put(setIsSavingShowAllECLs(true));
  if (!saveShowAllECLs.match(action)) {
    return;
  }
  const connectivityStatus: Connectivity = yield select((store: RootState) => store.offline.isOnline);
  const executionMode: ExecutionMode = yield select((store: RootState) => store.execution.mode);

  if (executionMode === ExecutionMode.Offline) {
    try {
      yield call([IdbApi, IdbApi.UpdateShowAllECLsForJob], action.payload.jobId, action.payload.showAllECLs);

      const logItem: ILogJobActionPayload = {
        action: "Show All ECLs was set as " + action.payload.showAllECLs,
        userName: action.payload.userName,
        userEmail: action.payload.userEmail,
        timestamp: action.payload.timestamp,
        isDirty: false,
      };

      yield put(logJobAction(logItem));

      // Also add that log item to the idb.
      let jobLogItem: ISWPJobLogItem = {
        ...logItem,
        jobId: action.payload.jobId,
        isOnServer: false,
        isDirty: false,
      }
      yield call([IdbApi, IdbApi.cacheJobLogItem], jobLogItem);
      yield put(setShowAllECLs({ showAllECLs: action.payload.showAllECLs }));
      yield put(refreshAwaitingSync());
    }
    catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  }
  else if (connectivityStatus === Connectivity.Online) {
    try {
      yield call(ExecutionApi.saveShowAllECLs, action.payload.jobId, action.payload.showAllECLs, action.payload.userEmail, action.payload.timestamp);
      yield put(setShowAllECLs({ showAllECLs: action.payload.showAllECLs }));
      let logitems: ISWPJobLogItem[] = yield call(JobsApi.getJobLog, action.payload.jobId);
      yield put(setLog(logitems));
    }
    catch (err: any) {
      yield put(showErrorToast(getResponseErrorMessage(err)));
    }
  }
  yield put(setIsSavingShowAllECLs(false));
}

function* cacheCompleteJobAsync(action: Action) {
  if (!(cacheCompleteJob.match(action) || saveStepsDataAndCompleteJob.match(action))) {
    return;
  }

  yield put(setIsJobCompleting(true));

  try {
    // Cache the job completion to the Idb.
    yield call([IdbApi, IdbApi.cacheJobCompletion], {
      jobId: action.payload.jobId,
      deviationMessage: action.payload.deviationMessage,
      timestamp: action.payload.timestamp,
      isOnServer: false,
      userEmail: action.payload.userEmail,
    });
  } catch (err: any) {
    yield put(setCompleteJobError(getResponseErrorMessage(err)));
  }

  // Add a log item to the store for completing the job.
  const store: RootState = yield select((store: RootState) => store);

  const logItem: ILogJobActionPayload = {
    action: "Completed job"
      + (action.payload.deviationMessage
        ? ` with deviation message: ${action.payload.deviationMessage}`
        : ""),
    userName: store.execution.executor?.name || store.auth.currentUser.name,
    userEmail: store.execution.executor?.email || store.auth.currentUser.email,
    timestamp: new Date(),
    isDirty: false,
  };

  yield put(logJobAction(logItem));

  // Also add that log item to the idb.
  let jobLogItem: ISWPJobLogItem = {
    ...logItem,
    jobId: action.payload.jobId,
    isOnServer: false,
    isDirty: false,
  }
  yield call([IdbApi, IdbApi.cacheJobLogItem], jobLogItem);

  // Update job locally to show completed.
  yield put(setJobStatus(SWPJobStatus.Completed));
  yield put(setIsCompleteJobModalVisible(false));
  yield put(setIsJobCompletedModalVisible(true));
  yield put(refreshAwaitingSync());

  yield put(setIsJobCompleting(false));
}

export function* cacheStepCommentAtt(blobRef: IAzureBlobRef) {
  // Check if the step comment att is already in the idb.
  // If so, don't load anything.
  const isInIdb: boolean = yield call([IdbApi, IdbApi.isStepCommentAttInCache],
    blobRef.filename);

  if (isInIdb) {
    return;
  }

  // Get att from Azure & parse into data Uri.
  const dataUri: string = yield call(AzureBlobsApi.getDataUri, blobRef.signedUrl);

  // Put the dataUri string into the idb.
  yield call([IdbApi, IdbApi.cacheStepCommentAttData],
    blobRef.filename,
    dataUri);
}

function* watchHandleRealtimeStepUpdate() {
  yield takeEvery(handleRealtimeStepUpdate, handleRealtimeStepUpdateAsync);
}

function* handleRealtimeStepUpdateAsync(action: Action) {
  if (!handleRealtimeStepUpdate.match(action)) {
    return;
  }

  const execState: IExecutionState = yield select((store: RootState) => store.execution);
  const {
    job,
    stepResponses,
  } = execState;

  if (!job) {
    return;
  }

  const {
    sws,
  } = job;

  // Check each step info to see if it's already in response.
  // If not, then need to load from backend.
  const stepInfos = action.payload;

  try {
    const stepsToLoad: IRealtimeStepResponseInfo[] = stepInfos
      .filter(stepInfo => {
        if (!sws.find(x => x.jobSWId === stepInfo.jobSWId)) {
          // This step is for a jobSWId not on the client.
          return false;
        }

        const oldResp = stepResponses
          .find(x => x.jobSWId === stepInfo.jobSWId
            && x.stepId === stepInfo.stepId);

        const stepInfoDate = new Date(stepInfo.timestamp);

        if (oldResp
          && oldResp.timestamp.getTime() === stepInfoDate.getTime()
          && oldResp.userEmail === stepInfo.userEmail) {
          return false;
        }

        return true;
      });

    if (!stepsToLoad.length) {
      return;
    }

    const jobId = stepsToLoad[0].jobId;

    // StepsToLoad: load each from the server.
    yield put(beginRealtimeStepLoads(stepsToLoad.map((x): IStepIdentifier => ({
      stepId: x.stepId,
      jobSWId: x.jobSWId,
    }))));

    const getResponsesResult: IGetJobResponsesResult = yield call(ExecutionApi.getResponses,
      jobId,
      stepsToLoad.map(x => ({
        stepId: x.stepId,
        jobSWId: x.jobSWId,
      })));

    const responses = getResponsesResult.stepResponses;

    // Load each of the images, if they're not already in the cache.
    if (getResponsesResult.images.length) {
      yield all(getResponsesResult
        .images
        .map(i => call(loadResponseImageIfNotInDb, i, jobId)));
    }

    // Make sure the user still has the correct job loaded.
    const execStateCheck: IExecutionState = yield select((store: RootState) => store.execution);

    if (execStateCheck.job?.id !== jobId) {
      // User left the page and unloaded the job.
      return;
    }

    yield put(finishRealtimeStepLoads(responses));
  } catch (err: any) {
    yield put(showErrorToast(
      t('Failed to load realtime step update {error}. A page refresh is recommended.', { error: getResponseErrorMessage(err) })));
    return;
  }
}

function* watchHandleRealtimeCommentsUpdate() {
  yield takeEvery(handleRealtimeCommentsUpdate, handleRealtimeCommentsUpdateAsync);
}

function* handleRealtimeCommentsUpdateAsync(action: Action) {
  if (!handleRealtimeCommentsUpdate.match(action)) {
    return;
  }

  const execState: IExecutionState = yield select((store: RootState) => store.execution);
  const {
    job,
    stepComments,
  } = execState;

  const commentInfo = action.payload;

  if (!job
    || commentInfo.jobId !== job.id) {
    return;
  }

  // Check each comment guid to see if it's already in the job.

  try {
    const commentGuidsToLoad = commentInfo
      .commentGuids
      .filter(x => !stepComments
        .find(z => z.guid === x));

    if (!commentGuidsToLoad.length) {
      return;
    }

    // Load the comments from the server.
    const serverResult: IStepCommentResponse = yield call(ExecutionApi.getStepComments,
      commentInfo.jobId,
      commentGuidsToLoad);

    const {
      comments,
      azureAttachments,
    } = serverResult;

    // Load each of the attachments, if they're not already in the cache.
    if (azureAttachments.length) {
      yield call(batchCalls,
        5,
        azureAttachments,
        cacheStepCommentAtt);
    }

    // Make sure the user still has the correct job loaded.
    const execStateCheck: IExecutionState = yield select((store: RootState) => store.execution);

    if (execStateCheck.job?.id !== commentInfo.jobId) {
      // User left the page and unloaded the job.
      return;
    }

    yield put(finishRealtimeCommentLoads(comments));
  } catch (err: any) {
    yield put(showErrorToast(
      t('Failed to load realtime step update {error}. A page refresh is recommended.', { error: getResponseErrorMessage(err) })));
    return;
  }
}

function* watchHandleRealtimeJobHistoryLoad() {
  yield takeEvery(handleRealtimeJobHistoryLoad, handleRealtimeJobHistoryLoadAsync);
}

function* handleRealtimeJobHistoryLoadAsync(action: Action) {
  if (!handleRealtimeJobHistoryLoad.match(action)) {
    return;
  }

  const execState: IExecutionState = yield select((store: RootState) => store.execution);
  const {
    job,
  } = execState;

  const {
    jobId,
  } = action.payload;

  if (!job
    || jobId !== job.id) {
    return;
  }

  let log: ISWPJobLogItem[] = [];

  // Call jobs api to get job log.
  try {
    log = yield call(JobsApi.getJobLog, jobId);
  } catch (err: any) {
    yield put(showErrorToast(
      t('Failed to load realtime job history log {error}. A page refresh is recommended.', { error: getResponseErrorMessage(err) })));
    return;
  }

  if (!log.length) {
    return;
  }

  // Make sure the user still has the correct job loaded.
  const execStateCheck: IExecutionState = yield select((store: RootState) => store.execution);

  if (execStateCheck.job?.id !== jobId) {
    // User left the page and unloaded the job.
    return;
  }

  // Replace all the "on-server" log items locally with
  // the new ones from the server.
  yield put(finishRealtimeJobHistoryLoad(log));
}

function* watchHandleRealtimePaperExecution() {
  yield takeEvery(handleRealtimePaperExecution, handleRealtimePaperExecutionAsync);
}

function* handleRealtimePaperExecutionAsync(action: Action) {
  if (!handleRealtimePaperExecution.match(action)) {
    return;
  }

  const execState: IExecutionState = yield select((store: RootState) => store.execution);
  const {
    job,
    paperExecutions,
  } = execState;

  const {
    jobId,
  } = action.payload;

  const existingPaperExec = paperExecutions
    .find(x => x.timestamp.getTime() === action.payload.paperExec.timestamp.getTime()
      && x.userEmail.toLowerCase() === action.payload.paperExec.userEmail.toLowerCase());

  if (!job
    || jobId !== job.id
    || existingPaperExec) {
    return;
  }

  yield put(beginRealtimePaperExecLoad({
    jobSWId: action.payload.paperExec.jobIdORJobSWId,
  }));

  try {

    if (action.payload.paperExec.absoluteUri) {
      yield call(downloadAndCacheUserImage,
        {
          imageRef: {
            absoluteUri: action.payload.paperExec.absoluteUri,
            filename: action.payload.paperExec.proofFilename,
          },
          jobId: action.payload.jobId,
        });
    }
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
  } finally {
    if (!action.payload.paperExec.isJobPaperExecution) {
      yield put(finishRealtimePaperExecLoad({
        userEmail: action.payload.paperExec.userEmail,
        jobSWId: action.payload.paperExec.jobIdORJobSWId,
        timestamp: action.payload.paperExec.timestamp,
        imageFilename: action.payload.paperExec.proofFilename,
        isOnServer: true,
        isDirty: false,
      }));
    } else if (action.payload.paperExec.isJobPaperExecution) {
      yield put(finishRealtimeJobPaperExecLoad({
        userEmail: action.payload.paperExec.userEmail,
        jobId: action.payload.paperExec.jobIdORJobSWId,
        timestamp: action.payload.paperExec.timestamp,
        imageFilename: action.payload.paperExec.proofFilename,
        isOnServer: true,
        isDirty: false,
      }));
    }
  }
}

function* watchSaveOnlineSWUserFeedback() {
  yield takeLatest(saveOnlineSWUserFeedback, saveOnlineSWUserFeedbackAsync);
}

function* saveOnlineSWUserFeedbackAsync(action: Action) {
  if (!saveOnlineSWUserFeedback.match(action)) {
    return;
  }

  yield call(ExecutionApi.saveOnlineSWUserFeedback, action.payload.feedbacks);
  yield put(getOnlineSWUserFeedbacks({ jobId: action.payload.jobId, createdBy: action.payload.createdBy }));
}

function* watchGetOnlineSWUserFeedbacks() {
  yield takeLatest(getOnlineSWUserFeedbacks, getOnlineSWUserFeedbacksAsync);
}

function* getOnlineSWUserFeedbacksAsync(action: Action) {
  if (!getOnlineSWUserFeedbacks.match(action)) {
    return;
  }

  let feedbacks: ISWUserFeedback[] = yield call(ExecutionApi.getOnlineSWUserFeedbacks,
    action.payload.jobId,
    action.payload.createdBy);

  yield put(setOnlineSWUserFeedback({ feedbacks: feedbacks }));
}

function* watchSaveOfflineSWUserFeedback() {
  yield takeLatest(saveOfflineSWUserFeedback, saveOfflineSWUserFeedbackAsync);
}

function* saveOfflineSWUserFeedbackAsync(action: Action) {
  if (!saveOfflineSWUserFeedback.match(action)) {
    return;
  }

  // Cache user feeedback to IDB.
  try {
    const execState: IExecutionState = yield select((store: RootState) => store.execution);
    const {
      swUserFeedback,
    } = execState;

    const feedbacks: ISWUserFeedback[] = cloneDeep(swUserFeedback);
    let index = feedbacks.findIndex(x => x.jobId === action.payload.feedback.jobId &&
      x.swId === action.payload.feedback.swId &&
      x.version === action.payload.feedback.version &&
      x.createdBy === action.payload.feedback.createdBy)

    if (index >= 0) {
      feedbacks.splice(index, 1);
    }
    feedbacks.push(action.payload.feedback);

    yield put(addOfflineSWUserFeedback({ feedbacks: feedbacks }));
    yield put(addSWUserFeedback({ feedback: action.payload.feedback }));

    yield call([IdbApi, IdbApi.cacheSWUserFeedback], {
      jobId: action.payload.feedback.jobId,
      feedbacks: feedbacks,
      isOnServer: false,
    });

    yield put(refreshAwaitingSync());
    yield put(showSuccessToast(t("Your data has been saved to your device. Please sync data at the earliest opportunity.")));
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
  }
}

async function launchUserSentimentSurveyAsync(action: Action) {
  if (!launchUserSentimentSurvey.match(action)) {
    return;
  }

  try {
    var error = "";
    var token = await acquireUSSAccessToken();

    if (token.length > 0) {
      var url = config.endpoints.USS.validate
        .replace("{userID}",
          action.payload.currentUser
            .substr(0, action.payload.currentUser
              .lastIndexOf("@")))
      var request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.setRequestHeader("x-apikey", config.authConfigUSS.apiKey);
      request.setRequestHeader("authorization", "Bearer " + token);
      request.send();

      request.onload = function () {
        if (request.status >= 200 && request.status < 400) {
          var resData = JSON.parse(this.response);
          if (resData.isEligible) {
            window.open(resData.urlToRedirect,
              '_blank',
              'width=900,height=850,toolbar=0,menubar=0,location=0,top=10,left=25');
          }
        }
        else {
          error = 'Failed to validate user sentiment survey';
        }
      }
    }
    if (error.length > 0) {
      put(showErrorToast(
        t(error)));
    }
  }
  catch (err: any) {
    put(showErrorToast(
      t('Failed to launch user sentiment survey: {error}', { error: getResponseErrorMessage(err) })));
    return;
  }
}

function* resetJobPaperExecutionAsync(action: Action) {

  if (!resetJobPaperExecution.match(action)) {
    return;
  }

  const executionMode: ExecutionMode = yield select((store: RootState) => store.execution.mode);
  const connectivityStatus: Connectivity = yield select((store: RootState) => store.offline.isOnline);

  if (executionMode === ExecutionMode.Offline) {
    yield call(resetOfflineJobAsync, action.payload.jobId);
  } else if (connectivityStatus === Connectivity.Online) {
    yield call(ExecutionApi.resetOnlineJobPaperExecution, action.payload.jobId);
  } else { }

  yield saveExecutionDataAsync();
}


function* resetSWResponsesAsync(action: Action) {
  if (!resetSWResponses.match(action)) {
    return;
  }

  const executionMode: ExecutionMode = yield select((store: RootState) => store.execution.mode);
  const connectivityStatus: Connectivity = yield select((store: RootState) => store.offline.isOnline);

  if (executionMode === ExecutionMode.Offline) {
    yield call(resetOfflineSWresponsesAsync, action.payload.jobId, action.payload.jobSWId);
    yield saveExecutionDataAsync();
  }
  else if (connectivityStatus === Connectivity.Online) {
    // This will reset the SW in state and it will be updated to server on Save Data Button.
    yield put(resetSW({ jobSWId: action.payload.jobSWId }));
  }
  else {
    yield call(handleUploadResponseError, t("No internet connection. Please save data manually using the SAVE DATA button."));
  }
}

function* resetOnlineSWresponsesAsync(jobSWIds: number[]) {
  yield call(ExecutionApi.resetOnlineSWStepResponses, jobSWIds);
}

function* duplicateOnlinejobSWsAsync(jobSWIds: IDuplicateSWRequest[], jobid: number) {
  const saveResult: IDuplicateJobSW[] = yield call(ExecutionApi.duplicateSWsAddInJob, jobSWIds, jobid);
  return saveResult;
}

function* resetOfflineJobAsync(jobId: number) {
  try {
    const paperExec: IIdbPaperExecution[] = yield call([IdbApi, IdbApi.getCachedPaperExecutions], jobId);
    if (paperExec) {
      for (let index = 0; index < paperExec.length; index++) {
        const element = paperExec[index];
        yield call(resetOfflineSWresponsesAsync, jobId, element.jobSWId)
      }
    }
    const jobpaperExec: IIdbJobPaperExecution = yield call([IdbApi, IdbApi.getCachedJobPaperExecution], jobId);
    if (jobpaperExec) {
      yield call([IdbApi, IdbApi.deleteCachedJobPaperExecution], jobId);
      yield call([IdbApi, IdbApi.deleteCachedUserImage], jobpaperExec.imageFilename);
    } else {
      yield put(showErrorToast("No Job Paper Execution found"));
    }
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
  }
}

function* resetOfflineSWresponsesAsync(jobId: number, jobSWId: number) {
  let hadAnyFailures = false;

  try {
    let resetedJobSWs: IIdbResetSW | undefined = yield call([IdbApi, IdbApi.getCachedResetedSW], jobId);

    if (resetedJobSWs !== undefined) {
      if (!resetedJobSWs.jobSWIds.find(x => x === jobSWId)) {
        resetedJobSWs.jobSWIds.push(jobSWId);
      }
    }
    else {
      let jobSWIds: number[] = [];
      jobSWIds.push(jobSWId);
      resetedJobSWs = {
        jobId: jobId,
        jobSWIds: jobSWIds,
      };
    }

    yield call([IdbApi, IdbApi.cacheResetedSW], resetedJobSWs);
    yield call([IdbApi, IdbApi.deleteSWCahedData], jobId, jobSWId);
  } catch (err: any) {
    yield put(showErrorToast(getResponseErrorMessage(err)));
    hadAnyFailures = true;
  }

  if (!hadAnyFailures) {
    yield put(showSuccessToast("Your data has been saved to your device. Please sync data at the earliest opportunity."));
  }

  yield put(refreshAwaitingSync());
}
