import { IFileData } from "interfaces/files/fileInterfaces";
import { v4 as uuidv4 } from "uuid";

const imageExtensions = [
  "png",
  "jpg",
  "jpeg",
  "bmp",
  "gif",
  "tiff",
];

const videoExtensions = [
  "mp4",
  "avi",
  "mov",
];

const audioExtensions = [
  "m4a",
  "mp3",
  "wav",
];

const fileExtensions = ["pdf"];

export const imagesFilter = imageExtensions
  .map(x => `.${x}`)
  .join(',');

export const filesFilter = fileExtensions
  .map(x => `.${x}`)
  .join(',');

export function isFilenameImage(filename: string): boolean {
  const lastDot = filename.lastIndexOf('.');

  if (lastDot === -1) {
    return false;
  }

  const extension = filename.substr(lastDot + 1);

  return !!imageExtensions.find(x =>
    x.toLowerCase() === extension.toLowerCase());
}

export function isFilePDF(filename: string): boolean {
  const lastDot = filename.lastIndexOf('.');

  if (lastDot === -1) {
    return false;
  }

  const extension = filename.substring(lastDot + 1);

  return !!fileExtensions.find(x =>
    x.toLowerCase() === extension.toLowerCase());
}

export function getFileSizeError(file: File): string | undefined {
  if (file.size > 20971520) {
    return "File must be 20 MB or smaller.";
  }

  return undefined;
}

export function addGuidToFilename(filename: string): string {
  let lastDotIx = filename.lastIndexOf('.');

  if (lastDotIx === -1) {
    throw Error("Unknown file extension. Please select a valid file.");
  }

  let filenamePart = filename.substring(0, lastDotIx);
  let fileExtension = filename.substring(lastDotIx + 1);

  return `${filenamePart}_${uuidv4()}.${fileExtension}`;
}

export const readFileAsDataUri = (file: File): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.onerror = () => {
      reject(reader.error);
    }

    reader.onabort = () => {
      reject("File read was aborted.");
    }

    reader.readAsDataURL(file);
  });

export async function validateAndReadFile(file: File): Promise<IFileData> {
  const validationErr = getFileSizeError(file);

  if (validationErr) {
    throw new Error(validationErr);
  }

  const dataUri = await readFileAsDataUri(file);
  const filename = addGuidToFilename(file.name);
  return {
    filename,
    dataUri,
  };
}

export async function validateAndReadVideoFile(file: File): Promise<IFileData> {
  if (!isFilenameVideo(file.name)) {
    throw new Error("Please select a valid video.");
  }

  const validationErr = getVideoSizeError(file);

  if (validationErr) {
    throw new Error(validationErr);
  }

  const dataUri = await readFileAsDataUri(file);
  const filename = addGuidToFilename(file.name);
  return {
    filename,
    dataUri,
  };
}

export async function validateAndReadAudioFile(file: File): Promise<IFileData> {
  if (!isFilenameAudio(file.name)) {
    throw new Error("Please select a valid audio.");
  }

  const validationErr = getAudioSizeError(file);

  if (validationErr) {
    throw new Error(validationErr);
  }

  const dataUri = await readFileAsDataUri(file);
  const filename = addGuidToFilename(file.name);
  return {
    filename,
    dataUri,
  };
}

export function dataURItoBlob(dataURI: string): Blob {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else
    byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export function isFilenameVideo(filename: string): boolean {
  const lastDot = filename.lastIndexOf('.');

  if (lastDot === -1) {
    return false;
  }

  const extension = filename.substr(lastDot + 1);

  return !!videoExtensions.find(x =>
    x.toLowerCase() === extension.toLowerCase());
}

export function isFilenameAudio(filename: string): boolean {
  const lastDot = filename.lastIndexOf('.');

  if (lastDot === -1) {
    return false;
  }

  const extension = filename.substr(lastDot + 1);

  return !!audioExtensions.find(x =>
    x.toLowerCase() === extension.toLowerCase());
}

export function getVideoSizeError(file: File): string | undefined {
  if (file.size > 10485760) {
    return "Video must be 10 MB or smaller.";
  }

  return undefined;
}

export function getAudioSizeError(file: File): string | undefined {
  if (file.size > 10485760) {
    return "Audio must be 10 MB or smaller.";
  }

  return undefined;
}