import IdbApi from "apis/idb/IdbApi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showErrorToast } from "store/toast/toastActions";
import "./PrintVideo.scoped.scss";

interface IPrintVideoProps {
  filename: string,
}

const PrintVideo: React.FC<IPrintVideoProps> = ({
  filename,
}) => {
  const [imgData, setImgData] = useState<{
    filename: string,
    imgData: string,
  } | undefined>();
  const dispatch = useDispatch();
  const { t } = useTranslation('printView');

  useEffect(() => {
    let aborted = false;

    const loadData = async () => {
      // Load the initial signature from the Idb into the state if it exists.
      if (filename) {
        try {
          const imgData = (await IdbApi.getUserImageData(filename))?.data;

          if (!imgData) {
            throw new Error(t("Image not found in browser cache."));
          }

          if (!aborted) {
            setImgData({
              filename,
              imgData,
            });
          }
        } catch (err: any) {
          if (!aborted) {
            dispatch(showErrorToast(
              t('Failed to load existing signature {message}', {message: (err?.message || err?.toString())})));
          }
        }
      } else {
        if (!aborted) {
          setImgData(undefined);
        }
      }
    };

    loadData();

    return () => {
      aborted = true;
    };
  }, [filename, dispatch, t]);

  if (!imgData
    || !imgData?.filename
    || !imgData?.imgData) {
    return (
      <span>
        {t('(Invalid video)')}
      </span>
    );
  } else {
    return (
      <>
        <video controls className="embed">
            <source src={imgData.imgData} />
        </video>
      </>
    );
  }
};

export default PrintVideo;